import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

export const query = graphql`
    {
        post: contentfulTestimonials {
            content {
                shop
                person
                image
                text
                videoUrl
            }
        }
    }
`;

const VideoTemplate = ({ data: { post }, pageContext }) => (
    <section className="">
        {post.content
            .filter(function (i) {
                return i.shop == pageContext.shop;
            })
            .map((item, i) => (
                <div key={i} className="embed-container">
                    <iframe
                        src={`${item.videoUrl}?autoplay=0`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            ))}
    </section>
);

VideoTemplate.propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
};

export default VideoTemplate;
